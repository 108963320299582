import React from 'react'
import { Container, Box, Typography, Grid } from '@mui/material'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ArrowLeft from '../assets/svg/arrowLeft.svg'
import ArrowRight from '../assets/svg/arrowRight.svg'
import { useGetAllMentorsQuery } from '../store/features/apiMentors'
import DefaultImage from '../assets/default.jpg'

const Mentors = () => {
   const { data, isLoading, error } = useGetAllMentorsQuery()

   if (isLoading) return <Typography>Loading...</Typography>
   if (error) return <Typography>Error occurred: {error.message}</Typography>

   const mentors = data?.mentors || []

   // Настройки для слайдера
   const settings = {
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               arrows: false,
            },
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
            },
         },
      ],
   }

   return (
      <section>
         <Container
            maxWidth="lg"
            sx={{ margin: '50px auto', fontFamily: 'Inter' }}
         >
            <Box
               component="section"
               sx={{ marginBottom: { xs: '0', md: '87px' } }}
            >
               <Typography
                  variant="h2"
                  sx={{
                     fontSize: { xs: '24px', sm: '32px', md: '48px' },
                     fontWeight: 500,
                     textAlign: 'center',
                     marginBottom: { xs: '20px', sm: '40px', md: '70px' },
                  }}
               >
                  Менторлор
               </Typography>
               <Slider {...settings} style={{ padding: '0 20px' }}>
                  {mentors.map((mentor) => (
                     <div key={mentor.id}>
                        <Grid
                           item
                           sx={{
                              textAlign: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                           }}
                        >
                           <Box
                              sx={{
                                 position: 'relative',
                                 width: '100%',
                                 maxWidth: '367px',
                                 margin: '0 auto',
                              }}
                           >
                              <img
                                 src={
                                    mentor.imageMentor &&
                                    typeof mentor.imageMentor === 'string' &&
                                    mentor.imageMentor.startsWith('http')
                                       ? mentor.imageMentor
                                       : DefaultImage
                                 }
                                 alt={mentor.fullName}
                                 loading="lazy"
                                 style={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                 }}
                              />
                              <Typography
                                 variant="h6"
                                 sx={{
                                    fontSize: {
                                       xs: '16px',
                                       sm: '20px',
                                       md: '24px',
                                    },
                                    fontWeight: 500,
                                    marginTop: '10px',
                                 }}
                              >
                                 {mentor.fullName}
                              </Typography>
                              <Typography
                                 sx={{
                                    fontSize: {
                                       xs: '14px',
                                       sm: '16px',
                                       md: '18px',
                                    },
                                    fontWeight: 400,
                                    color: 'gray',
                                 }}
                              >
                                 {mentor.specialization}
                              </Typography>
                           </Box>
                        </Grid>
                     </div>
                  ))}
               </Slider>
            </Box>
         </Container>
      </section>
   )
}

// Кастомные стрелки
const NextArrow = ({ onClick }) => (
   <div
      onClick={onClick}
      style={{
         position: 'absolute',
         top: '50%',
         right: '-25px',
         transform: 'translateY(-50%)',
         cursor: 'pointer',
         zIndex: 2,
      }}
   >
      <img
         src={ArrowRight}
         alt="Next"
         style={{ width: '30px', height: '30px' }}
      />
   </div>
)

const PrevArrow = ({ onClick }) => (
   <div
      onClick={onClick}
      style={{
         position: 'absolute',
         top: '50%',
         left: '-25px',
         transform: 'translateY(-50%)',
         cursor: 'pointer',
         zIndex: 2,
      }}
   >
      <img
         src={ArrowLeft}
         alt="Previous"
         style={{ width: '30px', height: '30px' }}
      />
   </div>
)

export default Mentors
