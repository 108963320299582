import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import membersReducer from '../store/memberSlice'
import { api } from './features/apiSlice'
import { blogSlice, getBlogById } from './features/blogSlice'
import { partnersAdminApi } from './features/partnersSlice'
import { partnersApi } from './features/apiPartners'
import { scheduleApi } from './features/scheduleApi'
import userAuthSlice from './features/userAuthSlice'
import { authApi } from './features/authAPI'
import { blogUserApi } from './features/blogUserSlice'
import { getMentorSlice } from './features/mentorSlice'
import { reviewApi } from './features/reviewApi'
import { profileApi } from './features/profileApi'
import { mentorsApi } from './features/apiMentors'
export const store = configureStore({
   reducer: {
      [api.reducerPath]: api.reducer,
      [getBlogById.reducerPath]: getBlogById.reducer,
      [blogSlice.name]: blogSlice.reducer,
      [scheduleApi.reducerPath]: scheduleApi.reducer,
      [partnersApi.reducerPath]: partnersApi.reducer,
      [partnersAdminApi.reducerPath]: partnersAdminApi.reducer,
      members: membersReducer,
      [authApi.reducerPath]: authApi.reducer,
      [reviewApi.reducerPath]: reviewApi.reducer,
      [blogUserApi.reducerPath]: blogUserApi.reducer,
      [profileApi.reducerPath]: profileApi.reducer,
      userAuth: userAuthSlice,
      [getMentorSlice.reducerPath]: getMentorSlice.reducer,
      [mentorsApi.reducerPath]: mentorsApi.reducer,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
         api.middleware,
         getBlogById.middleware,
         getMentorSlice.middleware,
         scheduleApi.middleware,
         partnersApi.middleware,
         partnersAdminApi.middleware,
         authApi.middleware,
         profileApi.middleware,
         mentorsApi.middleware,
         reviewApi.middleware
      ),
})

setupListeners(store.dispatch)
export default store
