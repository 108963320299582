import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const mentorsApi = createApi({
   reducerPath: 'mentorsApi',
   baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
   }),

   endpoints: (builder) => ({
      getAllMentors: builder.query({
         query: () => ({
            url: `/mentor/getAll`,
         }),
      }),
   }),
})
export const { useGetAllMentorsQuery } = mentorsApi
