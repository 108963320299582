import React from 'react'
import styled from 'styled-components'
import students from '../assets/main-page-students.png'
import Mentors from '../components/Mentors'
import Partners from '../components/Partners'
import Direction from '../components/Direction'
import InfoPage from '../components/InfoPage'
// import Opinion from '../components/Opinions'
import Feedback from './FeedBack'
import { ReviewsSlider } from '../components/ReviewsSlider'
const MainSection = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   max-width: 1740px;
   margin: 0 auto;
   padding: 20px;

   @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
   }
`

const Column = styled.div`
   width: 100%;
   max-width: 471px;
   margin-bottom: 30px;
   text-align: start;

   @media (min-width: 768px) {
      margin-bottom: 0;
      padding-right: 29px;
   }
`

const MainTitle = styled.h1`
   margin: 0;
   font-weight: 600;
   font-size: 48px;
   line-height: 1.2;
   letter-spacing: 0.05em;
   text-transform: uppercase;
   text-align: start;

   @media (min-width: 768px) {
      font-size: 60px;
      text-align: start;
   }

   @media (min-width: 1024px) {
      font-size: 72px;
   }
`

const TitleGold = styled.span`
   color: rgb(205, 164, 7);
`

const SubTitle = styled.h4`
   font-weight: 600;
   font-size: 24px;
   color: rgb(122, 121, 121);
   text-align: start;
   margin: 10px 0;

   @media (min-width: 768px) {
      font-size: 30px;
      text-align: start;
   }

   @media (min-width: 1024px) {
      font-size: 36px;
   }
`

const MainSectionDescription = styled.p`
   font-weight: 600;
   font-size: 18px;
   line-height: 150%;
   text-align: start;
   margin-bottom: 20px;

   @media (min-width: 768px) {
      font-size: 20px;
   }

   @media (min-width: 1024px) {
      font-size: 24px;
   }
`

const MainBtn = styled.a`
   padding: 15px 30px;
   font-weight: 700;
   color: rgba(255, 255, 255, 0.83);
   font-size: 18px;
   line-height: 1.2;
   background-color: rgb(3, 22, 52);
   border-radius: 10px;
   text-decoration: none;
   text-align: center;
   margin:;

   &:hover {
      background-color: rgb(24, 46, 82);
   }

   @media (min-width: 768px) {
      font-size: 20px;
   }
`

const LinkContainer = styled.div`
   @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
   }
   margin-top: 20px;
`

const PhotoColumn = styled.div`
   width: 100%;
   max-width: 600px;
   height: auto;
   margin-top: 20px;

   @media (min-width: 768px) {
      margin-top: 0;
   }
   @media (max-width: 768px) {
      margin-top: 0;
      display: none;
   }
`

const MainImage = styled.img`
   width: 100%;
   height: auto;
   max-height: 474px;
   object-fit: contain;
`

const MainPage = () => {
   return (
      <Container>
         <MainSection style={{ margin: '40px 0' }}>
            <Column>
               <MainTitle>
                  Tech <TitleGold>Jol</TitleGold>
               </MainTitle>
               <SubTitle>aкысыз IT стажировка</SubTitle>
               <MainSectionDescription>
                  Разработка эффективного решения за максимально короткие сроки
                  по самым доступным и конкурентным в странах СНГ ценам.
               </MainSectionDescription>
               <LinkContainer>
                  <MainBtn href="/login">Tech Jolго кошулуу</MainBtn>
               </LinkContainer>
            </Column>
            <PhotoColumn>
               <MainImage src={students} alt="students" />
            </PhotoColumn>
         </MainSection>
         <Direction />
         <InfoPage />
         <Partners />
         <Mentors />
         <ReviewsSlider />
         {/* <Opinion/> */}
         <Feedback />
      </Container>
   )
}

export default MainPage

const Container = styled('div')(() => ({
   maxWidth: '1240px',
   margin: '0 auto',
}))
